import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Tabla from '../../components/Tabla2.js';

const Modulo = () => {
  let infoGeneral = useSelector((state) => state.infoGeneral.value);
  let infoUsuario = useSelector((state) => state.infoUsuario.value);

  //Escaner--------------------------------------------------------------------------------- 
  const searchParams = new URLSearchParams(window.location.search);
  const documentTypeMapping = {
    cc: 1,
    ti: 2,
    ce: 3,
    pasaporte: 4,
    nip: 5,
    nit: 6,
    id: 7,
    driving_license: 8,
    rnc: 9,
    rut: 10,
    visa: 11,
    pe: 12,
    pt: 13,
  };
  
  const urlData = {
    firstName: searchParams.get("nombres") || "",
    lastName: searchParams.get("apellidos") || "",
    documentTypeId: documentTypeMapping[searchParams.get("tipo")?.toLowerCase()] || "",
    documentNumber: searchParams.get("docu") || "",
    birthdate: searchParams.get("nacimiento") || "", 
    gender: ["f", "F"].includes(searchParams.get("sexo")) 
      ? "F"
      : ["m", "M"].includes(searchParams.get("sexo")) 
      ? "M"
      : "",
    phone: searchParams.get("telefono") || "",
    email: searchParams.get("correo") || "",
  };

  //Configurables---------------------------------------------------------------------------
  const titulo = "Pacientes";
  const icono = "fa-bed";
  const modals = [
    {
      titulo: "Nuevo Paciente",
      endpoint: "patient",
      buttonText: "Guardar",
      urlCall: "new",
      method: "POST",
      fields: [
        {
          label: "row", elements: [
            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false, value: urlData.firstName || "" },
            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false, value: urlData.lastName || "" },
          ]
        },
        {
          label: "row", elements: [
            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions, value: urlData.documentTypeId || "" },
            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false, value: urlData.documentNumber || "" },
          ]
        },
        {
          label: "row", elements: [
            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false, value: urlData.birthdate || "" },
            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }], value: urlData.gender || "" },
          ]
        },
        {
          label: "row", elements: [
            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false, value: urlData.phone || "" },
            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false, value: urlData.email || ""  },
          ]
        }
      ],
      dataMask: null
    },
    {
      titulo: "Editar Paciente",
      endpoint: "patient",
      buttonText: "Editar",
      urlCall: "edit",
      method: "PUT",
      fields: [
        {
          label: "row", elements: [
            { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
            { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
            { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
          ]
        },
        {
          label: "row", elements: [
            { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
            { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
          ]
        },
        {
          label: "row", elements: [
            { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
            { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
          ]
        }
      ],
      dataMask: null
    }
  ];
  let tabla1;
  if (infoUsuario.role.name == "ADMIN" || infoUsuario.role.name == "EMPLOYEE") {
    tabla1 = {
      endpoint: "patient",
      titulos: [
        { texto: "Nombres", name: "firstName" },
        { texto: "Apellidos", name: "lastName" },
        { texto: "Examenes", name: "examenes" },
        { texto: "Clave", name: "clave" },
        { texto: "Tipo Documento", name: "documentTypeId" },
        { texto: "Documento", name: "documentNumber" },
        { texto: "Email", name: "email" },
        { texto: "Teléfono", name: "phone" },
        { name: "status" }],
      columnas: [
        { prop: "firstName", type: "link", url: "edit/$id$" },
        { prop: "lastName", type: "link", url: "edit/$id$" },
        { prop: "documentNumber", type: "link", url: "../ordenes/filtrar/$id$?name=$firstName$+$lastName$", placeholder: "Ver Examenes" },
        { prop: "", type: "text", format: (x) => { return (x?.firstName[0] + x?.documentNumber.substr(x.documentNumber.length - 3)).toUpperCase() } },
        { prop: "documentTypeId", type: "text", format: (x) => { let selectTxt = infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x }); if (selectTxt.length > 0) { return selectTxt[0].text } } },
        { prop: "documentNumber", type: "text" },
        { prop: "email", type: "text" },
        { prop: "phone", type: "text" },
        { type: "status", url: "patient" },
      ],
    }
  } else {
    tabla1 = {
      endpoint: "patient",
      titulos: [
        { texto: "Nombres", name: "firstName" },
        { texto: "Apellidos", name: "lastName" },
        { texto: "Examenes", name: "examenes" },
        { texto: "Tipo Documento", name: "documentTypeId" },
        { texto: "Documento", name: "documentNumber" },
        { texto: "Email", name: "email" },
        { texto: "Teléfono", name: "phone" },
        { name: "status" }],
      columnas: [
        { prop: "firstName", type: "link", url: "../ordenes/filtrar/$id$?name=$firstName$+$lastName$" },
        { prop: "lastName", type: "link", url: "../ordenes/filtrar/$id$?name=$firstName$+$lastName$" },
        { prop: "documentNumber", type: "link", url: "../ordenes/filtrar/$id$?name=$firstName$+$lastName$", placeholder: "Ver Examenes" },
        { prop: "documentTypeId", type: "text", format: (x) => { let selectTxt = infoGeneral.documentTypeSelectOptions.filter((d) => { return d.value == x }); if (selectTxt.length > 0) { return selectTxt[0].text } } },
        { prop: "documentNumber", type: "text" },
        { prop: "email", type: "text" },
        { prop: "phone", type: "text" },
        { type: "status", url: "patient" },
      ],
    }

  }

  //Funciones Basicas-----------------------------------------------------------------------
  let { urlCall } = useParams();
  let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
  const navigate = useNavigate();
  let location = useLocation();

  const openModal = (modalNumber) => {
    navigate(modals[modalNumber].urlCall);
  }

  let [forceRender, setForceRender] = useState(0);
  const sendToRender = () => {
    setForceRender(forceRender + 1);
  };

  useEffect(() => {
    setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
  }, [location]);



  return (
    <>
      {modals.map((modal, index) => {
        return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
      })}


      <div className="pageHeading">
        <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
        {
          infoUsuario.role.name !== "DOCTOR" && infoUsuario.role.name !== "CLINIC" ?
            (
              <Link to="new">+</Link>
            )
            :
            (
              <></>
            )
        }
      </div>



      <Tabla info={tabla1} forceRender={forceRender} />

    </>
  );

};

export default Modulo;