import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from '../../components/Modal.js';
import Form from '../../components/Form.js';
import { useSelector } from 'react-redux'
import Api from '../../api.js';

const Modulo = () => {
    //Configurables---------------------------------------------------------------------------
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let api = new Api();
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    const titulo = "Mi Cuenta";
    const icono = "fa-gear";
    const form = {
        endpoint: "user/",
        buttonText: "Editar",
        method: "PUT",
        preLoad: true,
        fields: [
            {
                label: "row", elements: [
                    { label: "Información Personal", element: "subtitle" },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                    { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                    { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: true, isNumber: false },
                    { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Teléfono", name: "phone", element: "input", type: "tel", required: true, isNumber: false },
                    { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                ]
            }
        ],
        dataMask: null
    }

    const form2 = {
        endpoint: "user/update-password/",
        buttonText: "Cambiar Contraseña",
        method: "PUT",
        preLoad: false,
        fields: [
            {
                label: "row", elements: [
                    { label: "Información Personal", element: "subtitle" },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Contraseña Actual", name: "currentPassword", element: "input", type: "password", required: true, isNumber: false },
                ]
            },
            {
                label: "row", elements: [
                    { label: "Nueva Contraseña", name: "newPassword", element: "input", type: "password", required: true, isNumber: false, fun: () => { return; } },
                    { label: "Repetir Nueva Contraseña", name: "newPassword2", element: "input", type: "password", required: true, isNumber: false, fun: () => { return; } },
                ]
            },
        ],
        dataMask: {
            currentPassword: "currentPassword",
            newPassword: "newPassword"
        }
    }
    const modals = []
    //Funciones Basicas-----------------------------------------------------------------------
    let { urlCall } = useParams();
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));

    const navigate = useNavigate();
    let location = useLocation();

    const openModal = (modalNumber) => {
        navigate(modals[modalNumber].urlCall);
    }
    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    useEffect(() => {
        let newModalVisibility = modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } });
        if (newModalVisibility !== modalsVisibility) { setModalsVisibility(newModalVisibility); }
    }, [location]);

    let [alert, setAlert] = useState({ text: "", style: "alertHidden" });
    let op = useRef("");
    let np = useRef("");
    let np2 = useRef("");
    const checkMatchingPasswords = () => {
        if (np.current.value !== np2.current.value) {
            setAlert({ text: "Las contraseñas no coinciden", style: "alertError" })
        } else {
            setAlert({ text: "Las contraseñas coinciden", style: "alertSuccess" })
        }
    }

    const handleForm = async (event) => {
        event.preventDefault();

        if (alert.text == "Las contraseñas no coinciden") {
            return;
        }

        let resp = await api.put("user/update-password", { currentPassword: op.current.value, newPassword: np.current.value }, infoUsuario.token);

        if (!resp.error) {
            setAlert({ text: "Guardado", style: "alertSuccess" });
            sendToRender?.();

        } else {
            setAlert({ text: "La contraseña actual no es correcta", style: "alertError" });
        }
    }

    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}

            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            </div>

            <Form info={form} />

            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> Cambiar Contraseña</h1>
            </div>

            <form onSubmit={handleForm}>
                <p className={"alert " + alert.style}>{alert.text}</p>
                <div className="row">
                    <h2>Información Personal</h2>
                </div>

                <div className="row">
                    <div className="inputGroup">
                        <label>Contraseña Actual</label>
                        <input type="password" name="currentPassword" required={true} ref={op} />
                    </div>
                </div>

                <div className="row">
                    <div className="inputGroup">
                        <label>Nueva Contraseña</label>
                        <input type="password" name="newPassword" onChange={checkMatchingPasswords} required={true} ref={np} />
                    </div>

                    <div className="inputGroup">
                        <label>Repetir Nueva Contraseña</label>
                        <input type="password" name="newPassword2" onChange={checkMatchingPasswords} required={true} ref={np2} />
                    </div>
                </div>

                <div className="row row-pho">
                    <button type="submit">Cambiar Contraseña</button>
                </div>
            </form>
        </>
    );

};

export default Modulo;