import React, { useRef, useState, useEffect } from 'react';
import Api from '../../api.js';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Modal from '../../components/Modal.js';

import { setError, deleteError } from '../../store/infoGeneral.js'

const Nuevo = ({ instructions }) => {

    let api = new Api();

    const titulo = "Orden";
    const icono = "fa-file";

    const dispatch = useDispatch();
    let infoGeneral = useSelector((state) => state.infoGeneral.value);
    let infoUsuario = useSelector((state) => state.infoUsuario.value);
    let location = useLocation();
    let [listaPacientes, setListaPacientes] = useState([]);
    let [listaClinicas, setListaClinicas] = useState([]);
    let [listaDoctores, setListaDoctores] = useState([]);
    let [listaDoctoresPorClinica, setListaDoctoresPorClinica] = useState([]);
    let [listaSedes, setListaSedes] = useState([]);
    let [listaTipoExamenes, setListaTipoExamenes] = useState([]);
    let [showDoctor, setShowDoctor] = useState(false);
    let [paciente, setPaciente] = useState("");
    let [cliente, setCliente] = useState("");
    let [doctor, setDoctor] = useState("");
    let [sede, setSede] = useState("");
    let [tipoExamen, setTipoExamen] = useState("");
    let [comments, setComments] = useState("");
    let [examenes, setExamenes] = useState([]);

    const modals = [
        {
            titulo: "Nuevo Paciente",
            endpoint: "patient",
            buttonText: "Guardar",
            urlCall: "newPatient",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: true, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: true, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: true, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: null
        },
        {
            titulo: "Nueva Clinica",
            endpoint: "clinic",
            buttonText: "Guardar",
            urlCall: "newClinic",
            method: "POST",
            fields: [
                {
                    label: "row", elements: [
                        { label: "Información Clínica", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "RUT", name: "rut", element: "input", type: "text", required: false, isNumber: false },
                        { label: "Nombre Legal", name: "tradename", element: "input", type: "text", required: false, isNumber: false }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Representante Legal", element: "subtitle" }
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Nombres", name: "firstName", element: "input", type: "text", required: true, isNumber: false },
                        { label: "Apellidos", name: "lastName", element: "input", type: "text", required: true, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Tipo Documento", name: "documentTypeId", element: "select", required: false, isNumber: true, options: infoGeneral.documentTypeSelectOptions },
                        { label: "Numero Documento", name: "documentNumber", element: "input", type: "text", required: false, isNumber: false },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Fecha de Nacimiento", name: "birthdate", element: "input", type: "date", required: false, isNumber: false },
                        { label: "Sexo", name: "gender", element: "select", required: false, isNumber: false, options: [{ value: "", text: "" }, { value: "F", text: "Femenino" }, { value: "M", text: "Masculino" }] },
                    ]
                },
                {
                    label: "row", elements: [
                        { label: "Teléfono", name: "phone", element: "input", type: "tel", required: false, isNumber: false },
                        { label: "Email", name: "email", element: "input", type: "email", required: true, isNumber: false },
                    ]
                }
            ],
            dataMask: null
        }
    ];
    let { id } = useParams();
    const urlCall = "";
    let [modalsVisibility, setModalsVisibility] = useState(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));

    //Referencias
    let obj = useRef([]);

    let precioRef = useRef();
    let cantidadRef = useRef();
    const navigate = useNavigate();
    const documents = JSON.parse(localStorage.general).documentType;
    async function getStorageOptions(searchTerm, callback) {
        const filtered = await traerPacientes(searchTerm);
        callback(filtered.map((x) => {
            return {
                label: <span dangerouslySetInnerHTML={
                    {
                        __html: `${x.firstName} ${x.lastName}<br>${documents.find(d => d.id === x.documentTypeId)?.name}: ${x.documentNumber}`
                    }
                } />, value: x.id
            }
        }));
    }
    const optionsPacientes = listaPacientes.map((x) => {
        return {
            label: <span dangerouslySetInnerHTML={
                {
                    __html: `${x.firstName} ${x.lastName}<br>${documents.find(d => d.id === x.documentTypeId).name}: ${x.documentNumber}`
                }
            } />, value: x.id
        }
    });

    const optionsClientes = [...listaClinicas.map((x) => { return { label: "Clinica: " + x.clinicUsers[0].tradename, value: "c-" + x.id } }), ...listaDoctores.map((x) => { return { label: "Doctor: " + x.firstName + " " + x.lastName, value: "d-" + x.id } })];

    const optionsDoctoresPorClinica = listaDoctoresPorClinica.map((x) => { return { label: x.firstName + " " + x.lastName, value: x.id } });

    const optionsSedes = listaSedes.map((x) => { return { label: x.name, value: x.id } });

    const optionsTipoExamen = listaTipoExamenes.map((x) => { return { label: x.name, value: x.id } });

    useEffect(() => {
        if (infoUsuario.token !== null) {
            traerSedes();
        }
    }, [location, infoUsuario]);

    useEffect(() => {
        //setDoctor("");
        if (cliente !== "") {
            if (cliente.value.substring(0, 1) == "c") {
                traerDoctoresPorClinica();
                setShowDoctor(true);
            } else {
                setShowDoctor(false);
            }
        }
    }, [cliente])

    useEffect(() => {
        //limpiarExamenes();
    }, [doctor])

    useEffect(() => {
        async function tr() {
            if (tipoExamen == "") {
                setTipoExamen("");
                return;
            }
            /*if(cliente == ""){
                dispatch(setError("Debe seleccionar un cliente primero"));
                setTipoExamen("");
                return;
            }*/

            dispatch(deleteError());
            if (cliente !== "") {
                let clientId = cliente.value.slice(2);
                var info = await api.get("file-type/" + tipoExamen.value + "/client-price/" + clientId, infoUsuario.token);
            } else {
                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);
            }
            if (!info.error) {
                //setCantidad(1);
                cantidadRef.current.value = 1;
                if (info.info) {
                    precioRef.current.value = info.info.price;
                    return;
                }

                var info = await api.get("file-type/" + tipoExamen.value, infoUsuario.token);

                if (!info.error) {
                    //setPrecio(info.info.price);
                    precioRef.current.value = info.info.price;
                    return;
                }
            }
        }
        tr();
    }, [tipoExamen])

    useEffect(() => {
        setModalsVisibility(modals.map((modal) => { if (modal.urlCall != urlCall) { return "hiddenModal" } else { return "visibleModal" } }));
    }, [location]);


    let [forceRender, setForceRender] = useState(0);
    const sendToRender = () => {
        setForceRender(forceRender + 1);
    };

    async function agregarExamen() {
        if (tipoExamen == "" || cantidadRef.current.value == 0 || cantidadRef.current.value == "" || precioRef.current.value == 0 || precioRef.current.value == "") {
            dispatch(setError("Debe llenar toda la información sobre el tipo examen"));
            return;
        }
        let nombreTipoExamen = listaTipoExamenes.filter((tipoEx) => { return tipoEx.id == tipoExamen.value })[0].name;
        var ex = { id: tipoExamen, nombre: nombreTipoExamen, cantidad: cantidadRef.current.value, precio: precioRef.current.value };
        const nuevoExamen = { fileTypeId: tipoExamen.value, unitPrice: parseFloat(precioRef.current.value), quantity: parseInt(cantidadRef.current.value) };

        var resp = await api.post("order/" + id + "/detail", nuevoExamen, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());

            setExamenes([...examenes, ex]);
            setTipoExamen("");
            cantidadRef.current.value = 1;
            precioRef.current.value = 0;
        } else {
            dispatch(setError(resp.error));
        }
    }

    function limpiarExamenes() {
        setExamenes([]);
        setTipoExamen("");
        cantidadRef.current.value = 1;
        precioRef.current.value = 0;
    }

    async function eliminarExamen(idExamen) {
        if (window.confirm("Esta seguro que desea eliminar este examen?") !== true) {
            return;
        }
        var resp = await api.delete("order/" + id + "/detail/" + examenes[idExamen].id, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());

            let newExamenes = examenes.filter((ex, index) => { return index != idExamen });
            setExamenes(newExamenes);
        } else {
            dispatch(setError(resp.error));
        }
    }

    async function eliminarOrden() {
        if (window.confirm("Esta seguro que desea eliminar esta orden?") !== true) {
            return;
        }
        var resp = await api.delete("order/" + id, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());
            navigate("/ordenes");
        } else {
            dispatch(setError(resp.error));
        }
    }

    const submitForm = async (event) => {
        event.preventDefault();

        if (paciente == "") {
            dispatch(setError("Debe seleccionar un paciente"));
            return;
        }

        /*if(cliente == ""){
            dispatch(setError("Debe seleccionar un cliente"));
            return;
        }*/
        let doctorId;
        let clientId;
        if (cliente.value) {
            if (cliente.value.includes("c-")) {
                /*if(doctor == ""){
                    dispatch(setError("Debe seleccionar un doctor"));
                    return;
                }*/
                doctorId = doctor.value;
            } else {
                doctorId = null;
            }

            clientId = parseInt(cliente.value.slice(2));
        }


        if (sede == "") {
            dispatch(setError("Debe seleccionar una sede"));
            return;
        }

        if (examenes.length == 0) {
            dispatch(setError("Debe agregar uno o mas procedimientos"));
            return;
        }

        let nuevaOrden = {
            clientId: clientId,
            doctorId: doctorId,
            branchId: sede.value,
            comments: comments,
            detail: examenes.map((ex) => { return { unitPrice: parseFloat(ex.precio), quantity: parseInt(ex.cantidad) } })

        };

        var resp = await api.put("order/" + id, nuevaOrden, infoUsuario.token);

        if (!resp.error) {
            dispatch(deleteError());

            setPaciente("");
            setCliente("");
            setDoctor("");
            setSede("");
            setComments("");
            setExamenes([]);

            navigate("/ordenes/" + resp.info.id);
        } else {
            dispatch(setError(resp.error));
        }
    }




    async function traerDoctoresPorClinica() {
        let clientId = parseInt(cliente.value.slice(2));
        var info = await api.get("clinic/" + clientId + "/doctor", infoUsuario.token);

        if (!info.error) {
            setListaDoctoresPorClinica(info.info);
        }
    }

    async function traerSedes() {
        var info = await api.get("branch", infoUsuario.token);

        if (!info.error) {
            setListaSedes(info.info);
            traerPacientes();
        }
    }

    async function traerPacientes(search = '') {
        search = search != '' ? `&search=${search}` : '';
        var info = await api.get('patient?paginate=100&filter={"statusId":1}' + search, infoUsuario.token);

        if (!info.error) {
            setListaPacientes(info.info);
            traerClinicas();
            return info.info;
        }
    }

    async function traerClinicas() {
        var info = await api.get("clinic?paginate=2000", infoUsuario.token);

        if (!info.error) {
            setListaClinicas(info.info);
            traerDoctores();
        }
    }

    async function traerDoctores() {
        var info = await api.get("doctor?paginate=2000", infoUsuario.token);

        if (!info.error) {
            setListaDoctores(info.info);
            traerTipoExamenes();
        }
    }

    async function traerTipoExamenes() {
        var info = await api.get("file-type", infoUsuario.token);

        if (!info.error) {
            setListaTipoExamenes(info.info);
            traerOrden();
        }
    }

    async function traerOrden() {
        var info = await api.get("order/" + id, infoUsuario.token);

        if (!info.error) {
            setSede({ label: info.info.branch?.name, value: info.info.branch?.id });
            setPaciente({ label: info.info.patient.firstName + " " + info.info.patient.lastName, value: info.info.patient.id });

            if (info.info.client) {
                if (info.info.client.hasOwnProperty("tradename")) {
                    setCliente({ label: "Clinica: " + info.info.client.tradename, value: "c-" + info.info.client.id });
                    if (info.info.doctor) {
                        setDoctor({ label: info.info?.doctor?.firstName ?? "" + " " + info.info?.doctor?.lastName ?? "", value: info.info?.doctor?.id ?? "" });
                        setShowDoctor(true);
                    }
                } else {
                    setCliente({ label: "Clinica: " + info.info.client.tradename, value: "c-" + info.info.client.id });
                    setShowDoctor(false);
                }
            }

            setComments(info.info.comments);

            setExamenes(info.info.orderDetails.map((ex) => {
                return { id: ex.id, nombre: ex.fileType.name, cantidad: ex.quantity, precio: ex.unitPrice }
            }))

        }
    }

    let error = '';
    if (infoGeneral.error.length) {
        error = <p className="error">{infoGeneral.error}</p>;
    }
    return (
        <>
            {modals.map((modal, index) => {
                return <Modal key={index} modalNumber={index} visibility={modalsVisibility} info={modal} sendToRender={sendToRender} />
            })}

            <div className="pageHeading">
                <h1><FontAwesomeIcon icon={icono} /> {titulo}</h1>
            </div>
            {error}

            <form onSubmit={submitForm}>
                <div className="row">
                    <h2>Nueva Orden</h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Paciente</label>
                        <AsyncSelect
                            styles={
                                {
                                    container: (base) => ({ ...base, width: "100%" })
                                }
                            }
                            value={paciente}
                            onChange={setPaciente}
                            isSearchable={true}
                            required={true}
                            name="paciente"
                            loadOptions={getStorageOptions}
                            defaultOptions={optionsPacientes}
                        />
                        <Link to="newPatient" className="newElement">Agregar Nuevo</Link>
                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Cliente</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={cliente} onChange={setCliente} isSearchable={true} required={false} name="cliente" options={optionsClientes} />
                        <Link to="newClinic" className="newElement">Agregar Nuevo</Link>
                    </div>
                    <div className="inputGroup" style={showDoctor ? { display: "flex" } : { display: "none" }}>
                        <label>Doctor</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={doctor} onChange={setDoctor} isSearchable={true} required={false} name="doctor" options={optionsDoctoresPorClinica} />
                    </div>

                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Sede</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} value={sede} onChange={setSede} isSearchable={true} required={true} name="sede" options={optionsSedes} />
                    </div>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Observaciones</label>
                        <textarea name="comments" required={false} rows="3" onChange={e => setComments(e.target.value)} value={comments}></textarea>
                    </div>
                </div>
                <div className="row">
                    <h2>Examenes</h2>
                </div>
                <div className="row">
                    <div className="inputGroup">
                        <label>Tipo Examen</label>
                        <Select styles={{ container: (base) => ({ ...base, width: "100%" }) }} defaultValue={tipoExamen} onChange={setTipoExamen} isSearchable={true} required={false} name="tipoExmane" options={optionsTipoExamen} />

                    </div>
                    <div className="inputGroup">
                        <label>Cantidad</label>
                        <input type="number" name="paciente" defaultValue={1} required={true} ref={cantidadRef} />
                    </div>
                    <div className="inputGroup">
                        <label>Precio</label>
                        <input type="text" name="paciente" defaultValue={0} required={true} ref={precioRef} />
                    </div>
                    <div className="inputGroup">
                        <label>&nbsp;</label>
                        <a className="btn" onClick={agregarExamen}>Agregar</a>
                    </div>
                </div>

                <table>
                    <thead>
                        <tr>
                            <td>Tipo Examen</td>
                            <td>Cantidad</td>
                            <td>Precio</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {examenes.map((examen, index) => {
                            return <tr key={index}><td>{examen.nombre}</td><td>{examen.cantidad}</td><td>${examen.precio}</td><td><a className="btn delete" onClick={() => { eliminarExamen(index) }}>Eliminar</a></td></tr>
                        })}
                    </tbody>
                </table>

                <div className="row row-pho">
                    <button type="submit">Editar</button>
                    <button type="button" className="delete" style={{ float: "right", marginLeft: "100px" }} onClick={eliminarOrden}>Eliminar</button>
                </div>
            </form>


        </>
    );

};

export default Nuevo;